import React from 'react';
import logo from '../img/logo-transparent.png';


function Footer(props) {
  return (

        <div className="footer" style={{display:'flex', flexDirection:'row', minHeight:'15vh', justifyContent: 'space-around', alignItems:'center'}}>
          <a href="https://takshashila.org.in">
            <div className="footer-takshashila" style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
              <img src={logo} className="footer-logo" alt="logo" style={{paddingRight:'3vh'}} />
              <p className="footer-text"> A Takshashila Initiative</p>
            </div>
          </a>

          <p className="footer-text"> Developed by Shruthi Badri</p>

        </div>
  );
}

export default Footer;

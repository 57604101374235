import React, { Component } from 'react';
import quizQuestions from './api/quizQuestions';
import Quiz from './components/Quiz';
import ResultTest from './components/ResultTest';
import Welcome from './components/Welcome';
import Footer from './components/Footer';
// import ContestBanner from './components/ContestBanner';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import './App.css';
import './index.css'



class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      started: false,
      counter: 0,
      questionId: 1,
      question: '',
      answerOptions: [],
      answer: -1,
      complete: false,
      identity: 0,
      liberty: 0,
      score:[],
    };

    this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
    this.confirmAnswer = this.confirmAnswer.bind(this);
    this.startQuiz = this.startQuiz.bind(this);
  }

  componentDidMount() {
    const shuffledAnswerOptions = quizQuestions.map(question =>
      this.shuffleArray(question.answers)
    );
    this.setState({
      question: quizQuestions[0].question,
      answerOptions: shuffledAnswerOptions[0]
    });
  }

  shuffleArray(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  handleAnswerSelected(event) {
    let i = parseInt(event.currentTarget.value);
    this.setState((state, props) => ({
      answer: i
    }));

//     let identity = quizQuestions[this.state.counter].answers[i].identity;
//     let liberty = quizQuestions[this.state.counter].answers[i].liberty;
//     this.setUserAnswer(i, identity, liberty) ;
//
//     if (this.state.questionId < quizQuestions.length) {
//       setTimeout(() => this.setNextQuestion(), 300);
//     } else {
//       setTimeout(() => this.setResults(), 300);
//     }
  }

  confirmAnswer(event){

    let i = this.state.answer
    console.log(this.state.counter)
    let identity = quizQuestions[this.state.counter].answers[i].identity;
    let liberty = quizQuestions[this.state.counter].answers[i].liberty;

    this.setUserAnswer(i, identity, liberty) ;

    if (this.state.questionId < quizQuestions.length) {
      setTimeout(() => this.setNextQuestion(), 300);
    } else {
      setTimeout(() => this.setResults(), 300);
    }

  }

  setUserAnswer(i, identity, liberty) {
    this.setState((state, props) => ({
      identity: state.identity + parseInt(identity),
      liberty: state.liberty + parseInt(liberty)
    }));
    console.log(i)
    console.log("Liberty: " + this.state.liberty);
    console.log("Identity: " + this.state.identity);

  }

  setNextQuestion() {
    const counter = this.state.counter + 1;
    const questionId = this.state.questionId + 1;

    this.setState({
      counter: counter,
      questionId: questionId,
      question: quizQuestions[counter].question,
      answerOptions: quizQuestions[counter].answers,
      answer: -1
    });
  }

  setResults() {
    this.setState({ complete: true });
  }

  startQuiz(){
    this.setState({ started: true});
  }

  renderQuiz() {
    return (

      <Quiz
        answer={this.state.answer}
        answerOptions={this.state.answerOptions}
        questionId={this.state.questionId}
        question={this.state.question}
        questionTotal={quizQuestions.length}
        onAnswerSelected={this.handleAnswerSelected}
        confirmAnswer={this.confirmAnswer}
      />
    );
  }

  renderResult() {
    let redirectString = "/result/" + this.state.identity + "/" + this.state.liberty
    return <Redirect to={redirectString} /> 
  }

  renderWelcome() {
    return <Welcome start={this.startQuiz} />
  }

  // renderContestBanner() {
  //   return <ContestBanner />
  // }

  render() {
    return (
      <Router>
      <div>

          <Switch>
            <Route path="/result/:identity/:liberty" component={ResultTest}>
            </Route>
            <Route path="/">
              <div className="App">

                {/* <div className="App-header"> */}
                {/*   <img src={logo} className="App-logo" alt="logo" /> */}
                {/* </div> */}
                {this.state.complete ?
                  this.renderResult() :
                  this.state.started? this.renderQuiz():
                  this.renderWelcome()
                }
                <Footer />
              </div>

            </Route>


          </Switch>
      </div>
    </Router>

    );
  }
}

export default App;

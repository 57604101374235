import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import mandala from '../img/mandala.png'

import { Stage, Layer, Rect, Text, Circle, Line, Image } from 'react-konva';

import {
  EmailShareButton, FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton,
  EmailIcon, FacebookIcon, TelegramIcon, TwitterIcon, WhatsappIcon,
} from "react-share";

import useImage from 'use-image';

const isMobile = window.innerWidth < 500;
const size = isMobile? 0.8*window.innerWidth: 500  ;


const MandalaImage = () => {
  const [image] = useImage(mandala);
  return <Image image={image} width={size} height={size} />;
};

function ResultTest({match}) {

  console.log(isMobile)
  let i = match.params.identity;
  let l = match.params.liberty;
  let typeDescriptions = {
  libreg:{name:"Liberal Regionalist", info:"You have registered high scores on both the liberty and the identity dimensions. You attach the greatest importance to your regional or local identity and believe that members of your collective ought to have the maximum freedom. You are suspicious of international organisations or national governments interfering in what you see as matters that are the preserve of the individual or the local community."},
  conservative:{name:"Conservative", info:"Your responses have earned a high identity score and a middling score on the liberty axis. In many ways, you are a traditionalist, particularly when it comes to social values. Conservatives attach a lot of importance to preserving regional and local culture and traditions, while attaching importance to economic freedom. This is reflected in your liberty score."},
  nativist:{name:"Nativist", info:"You’ve scored very high on the identity axis, and very low on liberty axis. Your political worldview entails prioritising the collective over the individual. More importantly, the boundaries of this collective are defined by a sharply defined group identity and are linked to the primary right over a specific territory and the resources contained therein."},
  libnat:{name:"Liberal Nationalist", info:"A high liberty score and middling identity score lands you in this square. As a liberal nationalist, you prioritise individual rights and freedoms. At the same time, you believe that the state is necessary to ensure the practical enjoyment of those rights and freedoms. You believe that the national interest lies in ensuring the well-being and development of all citizens."},
  centrist:{name:"Centrist", info:"You straddle a prickly fence and dislike extreme views on anything. A centrist’s perennial quest is to ensure a balance between contrasting positions. You are concerned by the possibility of society shifting towards any one particular end of the political spectrum. You are often criticised for being too moderate and not taking clear positions. It’s never easy being in the middle of it all."},
  cultnat:{name:"Cultural Nationalist", info:"A very low liberty score and middling identity score, place you in this bracket. Your political worldview is characterised by prioritising the collective over the individual and a strong sense of nationalism. This sense of nationalism arises from a sense of pride in cultural traditions which you believe is a unifying or defining factor of the people living in the country."},
  libertarian:{name:"Libertarian", info:"Your liberty score is at the higher end of the spectrum, while your identity score is at the lower end. Liberty is at the heart of your political worldview. As a Libertarian, you believe that the individual is at the centre of society. Your approach to socio-political issues is focused on maximising freedom and autonomy. You also harbour deep misgivings with regard to state power, and religious, cultural and familial authority figures."},
  progint:{name:"Progressive", info:"You score high on internationalism and have a middling score on individual liberty. You believe that in widening the moral arc beyond members of your own country, race and ethnic group, and that individual liberty can be abridged in order to attain social progress. You believe the state, international organisations and civil society institutions must promote socially progressive goals."},
  radical:{name:"Radical", info:"Radical Internationalists score very low on both the scales of liberty and identity. In essence, your worldview is unencumbered by traditional markers of identity, such as race, ethnicity, nationalism and regionalism. But that doesn’t imply prioritisation of the individual. What you are looking for is a new collective that transcends the existing norms."},

  }
   console.log(i);
   console.log(l);

  let type = 
            (i>5 && (
              (l>=5 && 'libreg') ||
              (l<5 && l>=-5 && 'conservative') ||
              ( l<-5 && 'nativist')))||
            (i<=5 && i>-6 && (
              (l>=5 && 'libnat') ||
              (l<5 && l>=-5 && 'centrist')||
              (l<-5 && 'cultnat')))||
            (i<=-6 && (
              (l>=5 && 'libertarian') ||
              (l<5 && l>=-5 && 'progint' )||
              (l<-5 && 'radical')))

  console.log(type);


  let colors = ['#7ED321', '#8B572A', '#D0021B',
                '#F57523', '#CCCCCC', '#d57ff0',
                '#FFDB58', '#00CDA9', '#2A6DE1'
               ]



  let shareString = `I took the political mandala quiz and discovered I am a ${typeDescriptions[type].name}. Check out my results here `
  let url = window.location.href


  return (
    <CSSTransitionGroup
      className="container result"
      component="div"
      transitionName="fade"
      transitionEnterTimeout={800}
      transitionLeaveTimeout={500}
      transitionAppear
      transitionAppearTimeout={500}
    >

      <div>

         <div style={{display:'flex', justifyContent:'center'}}>


            <Stage className="konvacanvas" width={size*5/4.5  } height={size+50} >
              {/* Colored Grid */}
              <Layer>
                 {[...Array(9)].map((x, i) =>
                    <Rect
                      x={(i%3)*(size/3)}
                      y={Math.floor(i/3)*(size/3)}
                      width={size/3}
                      height={size/3}
                      fill={colors[i]}
                    />
                  )}

                 {[...Array(30)].map((x, i) =>
                   <>
                    <Line
                      x={0}
                      y={i*(size/30)}
                      points={[0, 0, size, 0]}
                      stroke="#fff"
                      strokeWidth={0.4}
                    />

                    <Line
                      x={i*(size/30)}
                      y={0}
                      points={[0, 0, 0, size]}
                      stroke="#fff"
                      strokeWidth={0.4}
                    />
                    </>
                  )}


              </Layer>
              <Layer>
                 <MandalaImage />
              </Layer>
              <Layer>
              {/* Axes and labels*/}

              {/*TODO: Define aixs label components*/}
                 <Line x={size/2} y={0} points={[0, 0, 0, size]} stroke="#fff" strokeWidth={2.0}/>
                 <Line x={0} y={size/2} points={[0, 0, size, 0]} stroke="#fff" strokeWidth={2.0}/>

                 <Text text="Internationalist" x={0} y={size+5} fontSize={12}/>
                 <Text text="Nationalist" x={(size/2)-50} y={size+5} width={100} align="center" fontSize={12}/>
                 <Text text="Nativist" x={size-50} y={size+5} width={50} align="right" fontSize={12}/>
                 <Text text ="Identity"
                       x={(size/2)-50} y={size+20}
                       width={100} align="center"
                       fontFamily="Rubik" fontSize={15 }
                  />

                 <Text text="Low" x={size+5} y={size-15} fontSize={12} />
                 <Text text="High" x={size+5} y={0} fontSize={12} />
                 <Text rotation={90} text="Liberty" x={size+20} y={size/2-15  } align="center" fontFamily="Rubik" fontSize={15} />



              {/*User's point*/}

                  <Circle
                    x={(i/35*size) + (size/2)}
                    y={(size/2) - (l/29*size)}
                    radius={10}
                    fill="#333"
                    stroke="#fff" />

              </Layer>



            </Stage>
          </div>
 
        <h1> You appear to be a {typeDescriptions[type].name}.</h1>
        <p> {typeDescriptions[type].info}</p>
        <h2> How this quiz works</h2>
        <p> Our political beliefs are far more complex than Left vs Right. We have constructed this Political Mandala based on where people stand on two important dimensions: liberty and identity. A high liberty score reflects stronger support for individual freedoms and rights. On the other dimension, a high identity score indicates the importance of group identity in one’s political worldview.
        </p>
        <p>The Political Mandala is intended to give you pause for thought and for reflection to help you shape the world you live in, through political decisions that you make.
        </p>


         <h2> Share the quiz</h2>
         <FacebookShareButton url={url} quote={shareString}>
           <FacebookIcon size={45}/>
         </FacebookShareButton>
         <TelegramShareButton url={url}>
            <TelegramIcon size={45}/>
         </TelegramShareButton>
         <EmailShareButton url={url} subject="Check out the Political Mandala Quiz" body={shareString}>
             <EmailIcon size={45}/>
         </EmailShareButton>
         <WhatsappShareButton url={url} title={shareString} separator=" at: ">
             <WhatsappIcon size={45}/>
         </WhatsappShareButton>
         <TwitterShareButton url={url} title={shareString} >
             <TwitterIcon size={45}/>
         </TwitterShareButton>


         <h2 > About Takshashila</h2>
         <p>
           The Takshashila Institution is a networked think tank and a school of public policy physically located in Bangalore but operating around the world since 2010, over the internet.
         </p>
         <p>
           Takshashila is engaged with shaping India's relationship with the world, transforming how India is governed, and exploring the intersection of technology, economics and politics.
           Our public policy education programmes are tailored specifically for people like you. They are all online and you can take them from anywhere. Specialisations are available in defence & foreign affairs, technology policy and biotech policy.
         </p>
         <p>
           Engage with us by subscribing to our regular newsletters and podcasts.

         </p>


      </div>




    </CSSTransitionGroup>
  );
}

export default ResultTest;

import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import mandala from '../img/mandala.png'
// import ContestBanner from './ContestBanner';



function Welcome(props) {
  return (
    <CSSTransitionGroup
      className="welcome-outer"
      component="div"
      transitionName="fade"
      transitionEnterTimeout={800}
      transitionLeaveTimeout={500}
      transitionAppear
      transitionAppearTimeout={500}
    >
      <div className="welcome-inner">
        {/* <ContestBanner /> */}
        <br />
        <br />
        <div style={{display:'flex', justifyContent:'center'}}>
          <img src={mandala} className='App-logo' alt="mandala" style={{padding:'2vmin'}} />
        </div>
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems: 'center', textAlign: 'center'}}>
           <p className="welcome-subheading"> The Political Mandala quiz will help you
          parse through your political beliefs and see where you stand in the Indian political landscape. <br /> <br />
          Discover whether your political views square up with what you believe they are!
          </p>
           <button className="welcome-button" onClick={props.start}>Take The Quiz</button> <br />
        </div>
      </div>
    </CSSTransitionGroup>
  );
}


export default Welcome;
